<template>
  <b-row>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between align-items-center col-12">

      <h2>{{ $t('side_bar.tasks_label') }}</h2>

      <!--   CREATE NEW REPORT   -->
      <div
        class="my-1 float-right"
      >
        <modal-button
          ripple="rgba(113, 102, 240, 0.15)"
          size="xl"
          name="modal-main"
          :modalButton="$t('reusable.modal_add_btn')"
          @btn-emit="createNewTask"
          @open-emit="clearData()"
          :backdrop-close="true"
          :modal-title="$t('task_table.add_task_title')"
          variant="success"
        >
          <template v-slot:button @click="createNewTask">{{ $t('reusable.modal_add_btn') }}</template>

          <template v-slot:modal-body>
            <div class="d-flex flex-wrap">

              <!--   agent ID   -->
              <b-col cols="6" class="mb-1">

                <infinity-scroll-select
                  v-model="agent_id"
                  apiOption="filter.role.agent"
                  apiUrl="users.fetchUser"
                  :label="$t('task_table.choose_agent_id')"
                />

              </b-col>

              <!--   task type   -->
              <b-col cols="6" class="mb-1">

                <label>{{ $t('task_table.choose_task_type') }}</label>
                <v-select
                  v-model="taskOption"
                  :options="taskTypesOption"
                >
                </v-select>
              </b-col>

              <!--   task name   -->
              <b-col cols="6">
                <b-form-group
                  :label="$t('task_table.add_task_name')"
                  label-for="defaultLabel"
                >
                  <b-form-input
                    v-model="name"
                    size="lg"
                    :placeholder="$t('task_table.add_task_name')"
                  />
                </b-form-group>
              </b-col>

              <!--   deadline   -->
              <b-col cols="6">
                <b-form-group
                  :label="$t('task_table.choose_task_deadline')"
                  label-for="defaultLabel"
                >
                  <b-form-datepicker
                    style="width: 100%; z-index: 123"
                    size="lg"
                    id="example-datepicker"
                    v-model="deadline"
                    :locale="locale"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :placeholder="$t('task_table.choose_task_deadline')"
                  />
                </b-form-group>
              </b-col>

              <!--   description   -->
              <b-col cols="6" class="mb-1">
                <label for="textarea-default">{{ $t('task_table.add_description') }}</label>
                <b-form-textarea
                  id="textarea-default"
                  :placeholder="$t('task_table.add_description')"
                  rows="3"
                  v-model="description"
                />
              </b-col>

              <!--   execution_comment   -->
              <b-col cols="6" class="mb-1">
                <label for="textarea-default">{{ $t('task_table.add_comment') }}</label>
                <b-form-textarea
                  id="textarea-default"
                  :placeholder="$t('task_table.add_comment')"
                  rows="3"
                  v-model="execution_comment"
                />
              </b-col>

              <!--    add info     -->
              <b-card
                :title="$t('task_table.add_card_title')"
                class="my-3 card_box-shadow col-12"
                v-if="agent_id && filterAgent"
              >

                <span class="border-top-primary__parent">
                   <div class="d-flex flex-wrap align-items-center border-item"
                        v-for="(report,index) in reports" :key="index"
                   >
                  <div class="d-flex flex-wrap col-11 px-0 pt-2">

                    <!--   shop id   -->
                    <b-col cols="4" class="mb-2">
                      <infinity-scroll-select
                        v-model="report.shop_id"
                        :api-option="filterAgent"
                        apiUrl="shops.fetchShop"
                        @input="changed"
                        :label="$t('task_table.choose_shopId')"
                      />
                    </b-col>

                    <!--   REPORT TYPE   -->
                    <b-col cols="4" class="mb-2">
                      <label>{{ $t('report_table.choose_report_type') }}</label>
                        <v-select
                          v-model="report.type"
                          :options="reportTypesOption"
                        >
                        </v-select>

                    </b-col>

                    <!--   REPORT period TYPE   -->
                    <b-col cols="4" class="mb-2">

                      <label>{{ $t('report_table.choose_period_type') }}</label>
                      <v-select
                        v-model="report.date_period_type"
                        :options="periodTypesOption"
                      >
                      </v-select>
                    </b-col>

                  </div>

                  <div class="col-1">
                    <b-button
                      @click="removeInputField(index)"
                      class="btn-danger py-0 px-1"
                      style="font-size: 30px;"
                    >
                      -
                    </b-button>
                  </div>

                </div>

                </span>

                <div class="px-1">
                  <b-button
                    class="btn-success py-0"
                    style="font-size: 30px;"
                    @click="addInputField"
                  >
                    +
                  </b-button>
                </div>

              </b-card>

            </div>
          </template>
        </modal-button>

      </div>

    </div>

    <!--  ACCORDION  -->
    <app-collapse
      accordion
      class="px-1 mb-2 w-100"
    >
      <app-collapse-item :title="$t('reusable.filter')">
        <b-col
          md="4"
          class="my-1 px-0"
        >
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="md">
              <b-form-input
                id="filterInput"
                v-model="searchFilter"
                type="search"
                :placeholder="$t('reusable.search_placeholder')"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!searchFilter"
                  @click="searchFilter = ''"
                >
                  {{ $t('reusable.clear_btn') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </app-collapse-item>
    </app-collapse>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        sort-icon-left
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="searchFilter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        class="fixed__table"
      >

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">{{ $t('reusable.isBusy') }}</strong>
          </div>
        </template>

        <template #cell(date_period_type)="data">
          <span v-if="data.item.date_period_type == 'week'">
            {{ $t('reusable.week') }}
          </span>
          <span v-else>
            {{ $t('reusable.month') }}
          </span>
        </template>

        <template #cell(agent_status)="data">
          <span v-if="data.item.agent_status == 'opened'">{{ $t('reusable.agent_status_opened') }}</span>
          <span v-else-if="data.item.agent_status == 'in_progress'">{{ $t('reusable.agent_status_inProgress') }}</span>
          <span v-else-if="data.item.agent_status == 'completed'">{{ $t('reusable.agent_status_completed') }}</span>
          <span v-else>{{ $t('reusable.agent_status_overdue') }}</span>
        </template>

        <template #cell(admin_status)="{item}">
          <v-select
            class="select__overflow"
            :reduce="(option) => option.value"
            v-model="item['admin_status']"
            :options="adminStatusOptions"
            @input="selectAdminStatus(item,$event)"
          >
          </v-select>
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right fixed__position">

            <b-dropdown
              no-caret
              id="table-action-dropdown"
              variant="link"
              size="md"
            >
              <template
                #button-content
                class="p-0"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="p-0"
                />
              </template>

              <!--   PREVIEW BUTTON   -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="xl"
                  :name="`modal-preview-${data.item.id}`"
                  :backdrop-close="true"
                  :hide-footer="true"
                  :modalButton="$t('region_table.country_edit_modal_title')"
                  :id="data.item.id"
                  :modal-title="$t('reusable.preview_btn')"
                  :disableButton="!editAllow"
                  variant="flat-primary"
                  @open-emit="getTaskById(data.item.id)"
                  @btn-emit="updateTask(data.item.id, data.item)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="EyeIcon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.preview_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="d-flex flex-wrap">

                      <!--   agent ID   -->
                      <b-col cols="6" class="mb-1">

                        <infinity-scroll-select
                          v-model="agent_id"
                          apiOption="filter.role.agent"
                          apiUrl="users.fetchUser"
                          :label="$t('task_table.choose_agent_id')"
                        />

                      </b-col>

                      <!--   task type   -->
                      <b-col cols="6" class="mb-1">

                        <label>{{ $t('task_table.choose_task_type') }}</label>
                        <v-select
                          v-model="taskOption"
                          :options="taskTypesOption"
                          :disabled="!editAllow"
                        >
                        </v-select>
                      </b-col>

                      <!--   task name   -->
                      <b-col cols="6">
                        <b-form-group
                          :label="$t('task_table.add_task_name')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="name"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('task_table.add_task_name')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--   deadline   -->
                      <b-col cols="6">
                        <b-form-group
                          :label="$t('task_table.choose_task_deadline')"
                          label-for="defaultLabel"
                        >
                          <b-form-datepicker
                            style="width: 100%; z-index: 123"
                            size="lg"
                            id="example-datepicker"
                            v-model="deadline"
                            :disabled="!editAllow"
                            :locale="locale"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :placeholder="$t('task_table.choose_task_deadline')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--   description   -->
                      <b-col cols="6" class="mb-1">
                        <label for="textarea-default">{{ $t('task_table.add_description') }}</label>
                        <b-form-textarea
                          id="textarea-default"
                          :placeholder="$t('task_table.add_description')"
                          rows="3"
                          :disabled="!editAllow"
                          v-model="description"
                        />
                      </b-col>

                      <!--   execution_comment   -->
                      <b-col cols="6" class="mb-1">
                        <label for="textarea-default">{{ $t('task_table.add_comment') }}</label>
                        <b-form-textarea
                          id="textarea-default"
                          :placeholder="$t('task_table.add_comment')"
                          rows="3"
                          :disabled="!editAllow"
                          v-model="execution_comment"
                        />
                      </b-col>

                      <b-col cols="6">
                        <b-form-group
                          :label="$t('region_table.created_at')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="createdAt"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.created_at')"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group
                          :label="$t('region_table.updated_at')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="updatedAt"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.updated_at')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    add info     -->
                      <b-card
                        :title="$t('product_table.additional_information')"
                        class="my-3 card_box-shadow col-12"
                        v-if="agent_id && filterAgent"
                      >

                <span class="border-top-primary__parent">
                   <div class="d-flex flex-wrap align-items-center border-item"
                        v-for="(report,index) in reports" :key="index"
                   >
                  <div class="d-flex flex-wrap col-12 px-0 pt-2">

                    <!--   shop id   -->
                    <b-col cols="4" class="mb-2">
                      <infinity-scroll-select
                        v-model="report.shop_id.name"
                        :api-option="filterAgent"
                        apiUrl="shops.fetchShop"
                        @input="changed"
                        :disabled="!editAllow"
                        :label="$t('task_table.choose_shopId')"
                      />
                    </b-col>

                    <!--   REPORT TYPE   -->
                    <b-col cols="4" class="mb-2">
                      <label>{{ $t('report_table.choose_report_type') }}</label>
                        <v-select
                          v-model="reportTypes"
                          :options="reportTypesOption"
                          :disabled="!editAllow"
                        >
                        </v-select>

                    </b-col>

                    <!--   REPORT period TYPE   -->
                    <b-col cols="4" class="mb-2">

                      <label>{{ $t('report_table.choose_period_type') }}</label>
                      <v-select
                        v-model="periodTypes"
                        :options="periodTypesOption"
                        :disabled="!editAllow"
                      >
                      </v-select>
                    </b-col>

                  </div>

                </div>

                </span>

                      </b-card>

                    </div>
                  </template>


                </modal-button>
              </b-dropdown-item>

              <!--  EDIT BUTTON  -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="xl"
                  :backdrop-close="true"
                  :name="`modal-edit-${data.item.id}`"
                  :modalButton="$t('reusable.save_btn')"
                  :id="data.item.id"
                  :modal-title="$t('reusable.modal_edit_btn')"
                  variant="flat-warning"
                  @open-emit="getTaskById(data.item.id)"
                  @btn-emit="updateTask(data.item.id, data.item)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Edit2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_edit_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="d-flex flex-wrap">

                      <!--   agent ID   -->
                      <b-col cols="6" class="mb-1">

                        <infinity-scroll-select
                          v-model="agent_id"
                          apiOption="filter.role.agent"
                          apiUrl="users.fetchUser"
                          :label="$t('task_table.choose_agent_id')"
                        />

                      </b-col>

                      <!--   task type   -->
                      <b-col cols="6" class="mb-1">

                        <label>{{ $t('task_table.choose_task_type') }}</label>
                        <v-select
                          v-model="taskOption"
                          :options="taskTypesOption"
                        >
                        </v-select>
                      </b-col>

                      <!--   task name   -->
                      <b-col cols="6">
                        <b-form-group
                          :label="$t('task_table.add_task_name')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="name"
                            size="lg"
                            :placeholder="$t('task_table.add_task_name')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--   deadline   -->
                      <b-col cols="6">
                        <b-form-group
                          :label="$t('task_table.choose_task_deadline')"
                          label-for="defaultLabel"
                        >
                          <b-form-datepicker
                            style="width: 100%; z-index: 123"
                            size="lg"
                            id="example-datepicker"
                            v-model="deadline"
                            :locale="locale"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :placeholder="$t('deadline')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--   description   -->
                      <b-col cols="6" class="mb-1">
                        <label for="textarea-default">{{ $t('task_table.add_description') }}</label>
                        <b-form-textarea
                          id="textarea-default"
                          :placeholder="$t('task_table.add_description')"
                          rows="3"
                          v-model="description"
                        />
                      </b-col>

                      <!--   execution_comment   -->
                      <b-col cols="6" class="mb-1">
                        <label for="textarea-default">{{ $t('task_table.add_comment') }}</label>
                        <b-form-textarea
                          id="textarea-default"
                          :placeholder="$t('task_table.add_comment')"
                          rows="3"
                          v-model="execution_comment"
                        />
                      </b-col>

                      <!--    add info     -->
                      <b-card
                        :title="$t('product_table.additional_information')"
                        class="my-3 card_box-shadow col-12"
                        v-if="agent_id && filterAgent"
                      >

                <span class="border-top-primary__parent">
                   <div class="d-flex flex-wrap align-items-center border-item"
                        v-for="(report,index) in reports" :key="index"
                   >
                  <div class="d-flex flex-wrap col-11 px-0 pt-2">

                    <!--   shop id   -->
                    <b-col cols="4" class="mb-2">
                      <infinity-scroll-select
                        v-model="report.shop_id"
                        :api-option="filterAgent"
                        apiUrl="shops.fetchShop"
                        @input="changed"
                        :label="$t('task_table.choose_shopId')"
                      />
                    </b-col>

                    <!--   REPORT TYPE   -->
                    <b-col cols="4" class="mb-2">
                      <label>{{ $t('report_table.choose_report_type') }}</label>
                        <v-select
                          v-model="reportTypes"
                          :options="reportTypesOption"
                        >
                        </v-select>

                    </b-col>

                    <!--   REPORT period TYPE   -->
                    <b-col cols="4" class="mb-2">

                      <label>{{ $t('report_table.choose_period_type') }}</label>
                      <v-select
                        v-model="periodTypes"
                        :options="periodTypesOption"
                      >
                      </v-select>
                    </b-col>

                  </div>

                  <div class="col-1">
                    <b-button
                      @click="removeInputField(index)"
                      class="btn-danger py-0 px-1"
                      style="font-size: 30px;"
                    >
                      -
                    </b-button>
                  </div>

                </div>

                </span>

                        <div class="px-1">
                          <b-button
                            class="btn-success py-0"
                            style="font-size: 30px;"
                            @click="addInputField"
                          >
                            +
                          </b-button>
                        </div>

                      </b-card>

                    </div>

                  </template>

                </modal-button>
              </b-dropdown-item>

              <!--  DELETE BUTTON  -->
              <b-dropdown-item>
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="sm"
                  :name="`modal-delete-${data.item.id}`"
                  :modalButton="$t('region_table.country_delete_modal_title')"
                  :modal-title="$t('region_table.country_delete_modal_title')"
                  variant="flat-danger"
                  :id="data.item.id"
                  @btn-emit="deleteTask"
                >
                  <template v-slot:button class="w-100">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Trash2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_delete_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="my-2">
                      {{ $t('report_table.delete_text') }}
                    </div>
                  </template>

                </modal-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </template>

      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
      cols="12"
    >
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BFormDatepicker,
  BCard,
  BFormTextarea,
  BSpinner,
  BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ModalButton from '@/views/ui/modals/ModalButton.vue'
import api from '@/services/api'
import InfinityScrollSelect from '@/views/ui/infinity-scroll/InfinityScrollSelect'
import vSelect from 'vue-select'

export default {
  name: 'Task.vue',
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormDatepicker,
    BCard,
    BFormTextarea,
    BSpinner,
    AppCollapse,
    AppCollapseItem,
    BFormRadioGroup,
    BFormRadio,
    vSelect,
    ModalButton,
    InfinityScrollSelect
  },

  data() {
    return {
      createdAt: '',
      updatedAt: '',
      adminStatusOptions: [],
      adminStatusOption: {},
      taskTypesOption: [],
      taskOption: {},
      reportTypesOption: [],
      reportTypes: {},
      periodTypesOption: [],
      periodTypes: {},
      agent_id: null,
      name: '',
      description: '',
      execution_comment: '',
      deadline: '',
      reports: [{
        shop_id: null,
        type: null,
        date_period_type: null,
      }],
      locale: 'en-US',
      editAllow: false,
      isDeleted: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      searchFilter: null,
      filter: [],
      filterOn: [],
      status: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      statuses: [true, false],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: this.$t('task_table.task_name'),
          sortable: true,
        },
        {
          key: 'agent.full_name',
          label: this.$t('task_table.agent_fullName'),
          sortable: true,
        },
        {
          key: 'type',
          label: this.$t('task_table.task_type'),
        },
        {
          key: 'agent_status',
          label: this.$t('task_table.agent_status'),
        },
        {
          key: 'admin_status',
          label: this.$t('task_table.admin_status'),
        },
        {
          key: 'deadline',
          label: this.$t('task_table.deadline'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('region_table.created_at'),
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      variant: '',
      items: [],
      isBusy: false,
      pagination: {
        current: 1,
        total: 1,
        per_page: 5
      },
      fileRecords: [],
      uploadUrl: '',
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [],
    }
  },

  watch: {
    '$route.query': {
      handler(query) {
        this.pagination.current = query.page
        this.fetchTaskList()
      },
      deep: true,
      immediate: true
    },

    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    },

  },

  computed: {

    rows() {
      return this.items.length
    },

    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },

    filterAgent() {
      if (this.agent_id) {
        return 'filter.' + 'agent_id.' + this.agent_id.id
      }
    },
  },

  created() {
    this.getReportTypes()
    this.getTaskTypes()
  },

  methods: {
    async selectAdminStatus(item, value) {
      await api.tasks.changeAdminStatus(item.id, value)
      await this.fetchTaskList()
    },
    changed(val) {
      this.reports.map((item, index) => {
        if (item.product_id && item.product_id.id === val.id) {

          this.getVariationByProduct(item.product_id.id)
        }

      })
    },

    replaceRouter(query) {
      this.$router.replace({ query })
        .catch(() => {
        })
    },

    async fetchTaskList() {
      await this.getShopId()
      await this.getAdminStatus()

      const data = {
        with: {
          0: 'agent',
        },
        page: this.$route.query.page
      }
      this.isBusy = true
      await api.tasks.fetchTask(data)
        .then(res => {
          this.items = res.data.data
          this.pagination.per_page = res.data.meta.per_page
          this.pagination.total = res.data.meta.total
          this.pagination.current = res.data.meta.current_page
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    async getShopId() {
      await api.shops.fetchShop()
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
    },

    async getTaskById(id) {

      this.editAllow = false
      this.getReportTypes()
      this.getTaskTypes()
      const data = {
        with: {
          0: 'agent',
          1: 'reports.shop'
        }
      }
      await api.tasks.fetchOneTask(id, data)
        .then(res => {
          this.createdAt = res.data.created_at
          this.updatedAt = res.data.updated_at
          this.agent_id = res.data.agent
          this.taskOption = {
            label: res.data.type,
            value: res.data.type
          }
          this.name = res.data.name
          this.deadline = res.data.deadline
          const [day, month, year] = this.deadline.split('.')
          this.deadline = [year, month, day].join('-')
          this.description = res.data.description
          this.execution_comment = res.data.execution_comment

          this.reports[0].shop_id = res.data.reports[0].shop

          this.reportTypesOption.map(item => {
            if (item.value === res.data.reports[0].type) {
              this.reportTypes = item
            }
          })

          this.periodTypesOption.map(item => {
            if (item.value === res.data.reports[0].date_period_type) {
              this.periodTypes = item
            }
          })

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {

        })
    },

    createNewTask() {

      const [year, month, day] = this.deadline.split('-')
      this.deadline = [day, month, year].join('.')

      const formData = new FormData()

      for (let i = 0; i < this.reports.length; i++) {
        for (let [key, value] of Object.entries(this.reports[i])) {

          if (key === 'shop_id') {
            formData.append(`reports[${i}][${key}]`, [value.id])
          } else {
            formData.append(`reports[${i}][${key}]`, [value.value])
          }
        }
      }

      formData.append('agent_id', this.agent_id.id)
      formData.append('type', this.taskOption.value)
      formData.append('name', this.name)
      formData.append('description', this.description)
      formData.append('execution_comment', this.execution_comment)
      formData.append('deadline', this.deadline)

      api.tasks.createTask(formData)
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchTaskList()
        })
    },

    clearData() {
      this.agent_id = null
      this.taskOption = []
      this.name = ''
      this.deadline = null
      this.description = ''
      this.execution_comment = ''
      this.reports[0].shop_id = []
      this.periodTypes = {}
      this.reportTypes = {}
    },

    getReportTypes() {
      api.enums.fetchReportTypes()
        .then(res => {
          if (!(this.reportTypesOption.length || this.periodTypesOption.length)) {
            for (let [key, value] of Object.entries(res.data.type)) {
              this.reportTypesOption.push({
                'value': key, ...value
              })
            }
            for (let [key, value] of Object.entries(res.data.date_period_type)) {
              this.periodTypesOption.push({
                'value': key, ...value
              })
            }
          }

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.clearData()
        })
    },

    getTaskTypes() {
      api.enums.fetchTaskTypes()
        .then(res => {
          if (!(this.taskTypesOption.length)) {
            for (let [key, value] of Object.entries(res.data.types)) {
              this.taskTypesOption.push({
                'value': key, ...value
              })
            }
          }

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.clearData()
        })
    },

    getAdminStatus() {
      api.enums.fetchAdminStatus()
        .then(res => {
          console.log(res)
          if (!(this.adminStatusOptions.length)) {
            for (let [key, value] of Object.entries(res.data.admin_status)) {
              this.adminStatusOptions.push({
                'value': key, ...value
              })
            }
          }

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.clearData()
        })
    },

    deleteTask(id) {
      api.tasks.deleteTask(id)
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchTaskList()
        })
    },

    updateTask(id, data) {
      const [year, month, day] = this.deadline.split('-')
      this.deadline = [day, month, year].join('.')

      const formData = new FormData()

      for (let i = 0; i < this.reports.length; i++) {
        for (let [key, value] of Object.entries(this.reports[i])) {

          if (key === 'shop_id') {
            formData.append(`reports[${i}][${key}]`, [value.id])
          } else {
            formData.append(`reports[${i}][${key}]`, [value.value])
          }
        }
      }

      formData.append('agent_id', this.agent_id.id)
      formData.append('type', this.taskOption.value)
      formData.append('name', this.name)
      formData.append('description', this.description)
      formData.append('execution_comment', this.execution_comment)
      formData.append('deadline', this.deadline)

      api.tasks.updateTask(id, formData)
        .then(res => {
          this.agent_id = res.data.agent_id
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchTaskList()
        })
    },

    addInputField() {
      this.reports = [...this.reports, {
        shop_id: {},
        type: {},
        date_period_type: {},
      }]

    },

    removeInputField(value) {
      this.products.splice(value, 1)
    },

    // checkStatus(value) {
    //   if (value) return true
    //   return false
    // },

    changeVariant(value) {
      if (value.is_deleted) return 'danger'
      return 'success'
    },

    checkLocales(value) {
      if (localStorage.lang === 'ru') {
        return value.ru
      }
      return value.uz
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">

//.fixed__table {
//  overflow: initial;
//}
//
//.fixed__position .b-dropdown {
//  position: relative;
//}
//
//.fixed__position .b-dropdown ul.dropdown-menu {
//  left: -200px;
//}

[dir] .dropdown-item {
  padding: 0;
}

[dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::before {
  left: 0;
  top: 0;
  //position: static;
  margin-right: 12px;
}

[dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::after {
  left: 0;
  top: 0;
  margin-right: 12px;
}

.custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
  display: flex;
  align-items: center;
}

::v-deep .vs__dropdown-menu {
  max-height: 100px !important;
}

.list-group-wrapper {
  position: relative;
}

.list-group {
  overflow: auto;
  height: 100px;
  border: 2px solid #dce4ec;
  border-radius: 5px;
}

.list-group-item {
  margin-top: 1px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid #dce4ec;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

.card_box-shadow {
  box-shadow: 0 0 10px #1d172e !important;
}

.border-top-primary__parent {

  .border-item {
    border-bottom: 2px solid #1d172e;

  }

  .border-item:last-child {
    border-bottom: none !important;
  }

}

.select__overflow .vs__dropdown-toggle {
  width: 200px;
  font-size: 12px;
}

thead tr th {
  align-items: center;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem 1.5rem;
}

</style>
